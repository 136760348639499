const hostname = window.location.hostname;
const port = window.location.port;
const protocol = window.location.protocol;

// TODO: to test, override those variables and run. We should have unit test for those functions.
// const hostname: string = 'pysae.com';
// const port: number | undefined = undefined;

export function isLocalhost(): boolean {
  return hostname === 'localhost';
}

export function getCurrentParentDomain(): string {
  return hostname
    .split('.')
    .filter(p => p != 'app')
    .join('.');
}

export function isCurrentAppDomain(): boolean {
  return hostname.split('.').includes('app');
}

export function getParentBaseUrl(): string {
  return `${protocol}//${getCurrentParentDomain()}`;
}

export function getBaseApiUrl(): string {
  const baseAppUrl = getBaseAppUrl();

  return isCurrentAppDomain() ? baseAppUrl.replace('app.', 'api.') : baseAppUrl;
}

export function getBaseAppUrl(): string {
  const appPort = port ? `:${port}` : '';

  return `${protocol}//${hostname}${appPort}`;
}

export function asWsProtocol(url: string) {
  let urlObject: URL;
  try {
    urlObject = new URL(url);
  } catch (error) {
    if (!(error instanceof TypeError)) {
      throw error;
    }
    // Local development case
    urlObject = new URL(window.location.protocol + window.location.host + url);
  }

  if (urlObject.protocol !== 'ws:' && urlObject.protocol !== 'wss:') {
    urlObject.protocol = urlObject.protocol.endsWith('s:') ? 'wss:' : 'ws:';
  }

  return urlObject.toString();
}
